import React from "react";
import {InputTypeField} from "react-fields";
import SubscriptionActionHOC from "./subscriptionActionHOC";
import LazyLoad from 'react-lazyload';

const SubscriptionForm = SubscriptionActionHOC(props => {
    let divClasses = 'input-with-button';
    let btnClasses = '';
    if (props.formAttrs.small) {divClasses += ' input-with-button-download--small input-with-button--max-width-422';}
    if (props.formAttrs.big) {divClasses += ' input-with-button--70';}
    if (props.formAttrs.dark) {divClasses += ' input-with-button--dark';}
    if (props.formAttrs.white) {divClasses += ' input-with-button--white';}
    if (props.formAttrs.icon) {divClasses += ' input-with-button--icon';}

    if (props.formAttrs.secondary) {btnClasses += ' button--secondary';}

    let inlineIconClass = `icon icon--${props.formAttrs.inlineIcon || 'envelope'}`;

    let btnText = props.formAttrs.btnText || (props.formAttrs.hasGift ? 'DOWNLOAD' : 'Sign Up');

    return (
        <div className={divClasses}>
            <InputTypeField type='email' name="email" value={props.form.email} errorMessage={props.form.errors.email}
                            onChange={props.setEmail}
                            onKeyPress={props.submitOnEnter}
                            placeholder="Your email" />
            <button className={btnClasses} onClick={props.submitForm}>{props.formAttrs.icon ? <span className="icon icon--send"/> : <React.Fragment>{btnText} <span className={inlineIconClass} /></React.Fragment>}</button>
        </div>
    )
});

export default function LazyInlineInputSubscription(props) {
    return (<LazyLoad><SubscriptionForm {...props} /></LazyLoad>)
}
