import React from 'react';
import SearchBox from "../search/field";
import SubscribeButtonHandler from "../subscriptions/handler";

class Navigation extends React.Component {

    constructor(props) {
        super(props);
        this.toggleMenu = document.querySelector('#toggleMenu');
        this.bodyElement = document.querySelector('body');
    }

    componentDidMount() {
        if (this.toggleMenu) {
            this.toggleMenu.addEventListener('click', this.onClick,false);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    open = () => {
        this.toggleMenu.classList.add('active');
        this.bodyElement.classList.add('active-overlay');
        window.addEventListener('resize', this.onResize);
    };

    close = () => {
        this.toggleMenu.classList.remove('active');
        this.bodyElement.classList.remove('active-overlay');
        window.removeEventListener('resize', this.onResize);
    };

    onClick = () => {
        if (this.bodyElement.classList.contains('active-overlay')) {
            this.close();
        } else {
            this.open();
        }
    };

    onResize = () => {
        if (window.matchMedia('(min-width: 1025px)').matches && this.bodyElement.classList.contains('active-overlay')) {
            this.close();
        }
    };

    renderLink = (link) => {
        let linkOptions = {className: 'overlay-sub-sat pad-top-0'};
        if (link.blank) { linkOptions['target'] = '_blank' }
        return (
            <a key={`category${link.id}`} href={link.url} {...linkOptions}>{link.name.toLocaleUpperCase()}</a>
        )
    };

    renderTopSection = () => {
        return (<div className="overlay-menu__section overlay-menu__section--underlined overlay-menu__section--flex">
            <div className={'top-menu__grid--left'}>
                <a href={'/'}><img src='/images/hr-logo-white-thin.svg' alt='HR Addict' loading={'lazy'} width={81} height={14} /></a>
            </div>
            <div className={'top-menu__grid--right text-right'}>
                <div className="hamburger-icon hamburger-icon--active" onClick={this.close}>
                    <div className="hamburger-icon__bar hamburger-icon__bar--top"></div>
                    <div className="hamburger-icon__bar hamburger-icon__bar--middle"></div>
                    <div className="hamburger-icon__bar hamburger-icon__bar--bottom"></div>
                </div>
            </div>
        </div>)
    };

    render() {
        return (
            <div className="overlay-menu" id="overlay-menu">
                <div className="overlay-wrapper">
                    {this.renderTopSection()}
                    <div className={'overlay-menu__section overlay-menu__section--underlined'}>
                        <SearchBox forOverlayMenu={true} />
                    </div>
                    <div className={'overlay-menu__section overlay-menu__section--underlined fnt-bold'}>
                        {this.props.links.map((cat, i) => { return this.renderLink(cat); })}
                        <div className="overlay-line"></div>
                    </div>
                    <div className={'overlay-menu__section overlay-menu__section--underlined fnt-bold'}>
                        <SubscribeButtonHandler design={'sideNavigationMenu'} formType={'top_menu'} />
                    </div>
                    <div className={'overlay-menu__section fnt-15'}>
                        {/*<a href="/about" className="overlay-link">ABOUT US</a>*/}
                        <a href="/contact" className="overlay-link">CONTACT</a>
                        <a href="/about" className="overlay-link">ABOUT</a>
                        <a href="/terms" className="overlay-link">TERMS</a>
                        <a href="/privacy" className="overlay-link">PRIVACY</a>
                        {/*<div className="social-icons">*/}
                            {/*<a href='https://www.facebook.com/CareerAddict/' target='_blank'>*/}
                                {/*<div className={'small-icon facebook-white-bg'}/>*/}
                            {/*</a>*/}
                            {/*<a href='https://www.youtube.com/CareerAddictVideo' target='_blank'>*/}
                                {/*<div className={'small-icon youtube-white-bg'}/>*/}
                            {/*</a>*/}
                            {/*<a href='https://twitter.com/careeraddict' target='_blank'>*/}
                                {/*<div className={'small-icon twitter-white-bg'}/>*/}
                            {/*</a>*/}
                            {/*<a href='https://www.linkedin.com/company/career-addict/' target='_blank'>*/}
                                {/*<div className={'small-icon linkedIn-white-bg'}/>*/}
                            {/*</a>*/}
                            {/*<a href='https://www.instagram.com/careeraddict/' target='_blank'>*/}
                                {/*<div className={'small-icon instagram-white-bg'}/>*/}
                            {/*</a>*/}
                        {/*</div>*/}
                    </div>
                    <div style={{height: '70px'}}></div>
                </div>
            </div>

        );
    }
}

export default Navigation;